.instagram-gallery {
	max-width: 1000px;
	margin: auto;
	.instagram-gallery__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		.instagram-gallery__item {
			width: 23%;
			margin-bottom: 30px;
			background-color: #e6e6e6;
			a {
				display: block;
				overflow: hidden;
				position: relative;
				padding-top: 100%;
				img {
					width: 120%;
					max-width: inherit;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
	@media (min-width: 768px) {
		.instagram-gallery__list {
			&:before {
				content:"";
				display: block;
				width:23%;
				order:1;
			}
			&:after {
				content:"";
				display: block;
				width:23%;
			}
		}
	}
	@media (max-width: 767px) {
		.instagram-gallery__list {
			.instagram-gallery__item {
				width: 45%;
			}
		}
	}
}


